export const roleOptions = [
  { value: 'Sócio/Proprietário', label: 'Sócio/Proprietário' },
  { value: 'Vice-presidente', label: 'Vice-presidente' },
  { value: 'Diretor/C-Level', label: 'Diretor/C-Level' },
  { value: 'Gerente/Coordenador', label: 'Gerente/Coordenador' },
  { value: 'Supervisor', label: 'Supervisor' },
  { value: 'Analista/Assistente', label: 'Analista/Assistente' },
  { value: 'Consultor/Terceirizado', label: 'Consultor/Terceirizado' },
  { value: 'Estagiário', label: 'Estagiário' },
  { value: 'Outro', label: 'Outro' },
]

export const companySizeOptions = [
  { value: 'Menos de 10 funcionários', label: 'Menos de 10 funcionários' },
  { value: 'De 11 a 50 funcionários', label: 'De 11 a 50 funcionários' },
  { value: 'De 51 a 100 funcionários', label: 'De 51 a 100 funcionários' },
  { value: 'De 101 a 250 funcionários', label: 'De 101 a 250 funcionários' },
  { value: 'De 251 a 500 funcionários', label: 'De 251 a 500 funcionários' },
  { value: 'De 501 a 1000 funcionários', label: 'De 501 a 1000 funcionários' },
  { value: 'Acima de 1000 funcionários', label: 'Acima de 1000 funcionários' },
]
